html, body {
    height: 100%;
    font-size: 20px;
    font-family: 'Bitter', monospace;
    word-break: break-word;
    background-color: white;
}

.card-holder {
    display: flex;
    justify-content: center;
}

.card {
    background: white;
    border-radius: 12px;
    height: 600px;
    width: 600px;
    padding: 30px;
    margin: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.3s ease-in-out;
    transform: perspective(5000px) rotateY(var(--rotate-y, 0));
    transform-style: preserve-3d;
}

.card:hover {
    --rotate-y: 180deg;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
}

.front, .back {
    position: absolute;
    padding: 1rem;
    color: black;
    backface-visibility: hidden;
    transform: rotateX(0deg);
}

.front {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tags {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tag {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 5px;
}

.front p {
    text-align: center;
}

.back p {
    text-align: center;
}

.back {
    transform: rotateY(180deg);
    width: 100%;
    height: 100%;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}

.action-buttons {
    text-align: center;
    padding: 0;
}

.prev-next-button {
    color: white;
}
